<template>
    <div class="wrapper">

        <section class="encabezado -recursos-eventos">
            <h1 v-if="data">{{(data.section == 0) ? 'Recursos' : 'Eventos'}}</h1>
        </section>


        <section class="ficha-recurso container" v-if="data">

            <div class="top" v-if="data">

                <router-link :to="$t('links.recursos')" class="link-arrow -inverse">Volver</router-link>

                <!-- <h3 class="fecha">{{data.created_at | parsedate}}</h3> -->

                <h4 v-if="data.priority == 1">Destacado</h4>

                <h2>{{data.title}}</h2>
                <div class="categorias">
                    <a v-for="(n,key) in data.category" :key="key">{{domains.categories[n]}}</a>

                </div>
                <img :src="data.image" v-if="data.image" :alt="data.title">
                <p v-html="data.header"></p>


            </div>

            <div class="bottom" v-if="data">
                <template v-for="(el,key) in data.modules">

                    <template v-if="el">
                        <div :key="key" v-if="el.tipo =='texto'" class="parrafo">
                            <p v-html="el.texto"></p>
                        </div>

                        <div :key="key" class="contenedor-imagen" v-if="el.tipo == 'imagen'">
                            <img :src="el.enlace" v-if="el.tipo == 'imagen'" class="img-full">
                            <span v-html="el.pie" v-if="el.tipo == 'imagen'" class="pie"></span>
                        </div>

                        <h4 :key="key" :nivel="el.nivel" v-if="el.tipo == 'destacado'" class="destacado"
                            v-html="el.texto"></h4>

                        <h2 :key="key" v-if="el.tipo == 'titulo'" class="title--sup -green-line -black"
                            v-html="el.value"></h2>

                        <iframe :key="key" :src="$options.filters.urlToID(el.enlace)"
                            v-if="el.tipo == 'video'"></iframe>

                        <div :key="key" class="contenedor-link row-center" v-if="el.tipo == 'link'">

                            <div class="link row-start">
                                <a :href="el.enlace" class="enlace-cover" rel="noopener" target="_blank"></a>
                                <img v-if="!!el.meta.image" :src="el.meta.image.url" alt="">
                                <h2>{{el.meta.title}}</h2>
                                <p>{{el.meta.description}}</p>
                            </div>

                        </div>


                        <div :key="key" class="boton" v-if="el.tipo == 'boton'">
                            <a target="_blank" :href="el.enlace" class="btn -skull-blue">{{el.texto}}</a>
                        </div>

                        <div :key="key" class="descargable row-center" v-if="el.tipo == 'file'">
                            <a :href="el.enlace" class="enlace-cover" rel="noopener" target="_blank"></a>
                            <p v-if="!!el.titulo">{{el.titulo}} <a href="" class="download-link">Descargar</a></p>
                        </div>

                        <ul :key="key" v-if="el.tipo == 'lista' && el.estilo == 'unordered'">
                            <li :key="key" v-for="(el,key) in el.objectos" v-html="el"></li>
                        </ul>

                        <ol :key="key" v-if="el.tipo == 'lista' && el.estilo == 'ordered'">
                            <li :key="key" v-for="(el,key) in el.objectos" v-html="el"></li>
                        </ol>

                        <div class="table-container" :key="key" v-if="el.tipo == 'tabla'">
                            <table>
                                <tr v-for="(td,tdkey) in el.filas" :key="tdkey">
                                    <td :key="ekey" v-for="(e,ekey) in td" v-html="e">
                                        
                                    </td>
                                </tr>
                            </table>
                        </div>


                        <slider-recurso v-if="el.tipo == 'gallery'" :key="key" :data="el.imagenes"></slider-recurso>

                    </template>

                </template>





            </div>

        </section>

        <div class="loading" v-else></div>



    </div>
</template>


<script>

    import { mapActions, mapGetters } from 'vuex';

    import moment from 'moment';

    import sliderrecurso from '@/components/slider-recurso';

    export default {
        name: 'ficha-recurso',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: ''
        }),
        computed: {
            ...mapGetters({
                domains: 'getDomains'
            })
        },
        methods: {
            ...mapActions(['requestEntrie']),
            setData() {
                this.requestEntrie(this.$route.params.id).then(r => {
                    this.data = r;
                })
            }
        },
        components: {
            'slider-recurso': sliderrecurso
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            },
            urlToID(value) {

                let string;

                if (value.includes('youtube')) {
                    string = 'https://www.youtube.com/embed/' + value.split("?v=")[1];
                } else if (value.includes('vimeo')) {
                    string = 'https://player.vimeo.com/video/' + value.split("/").pop();
                }

                return string;
            }
        }
    }
</script>