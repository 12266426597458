<template>
    <div class="slider-recurso">
        <slick ref="slick" :options="slickOptions">
            <div class="slide" :key="key" v-for="(n,key) in data">
                <img :src="n.enlace" :alt="n.pie" @click="setslide(key)">
            </div>
        </slick>

        <div class="slider-popup" v-if="showslider">
            <div class="contenedor-slider" :show="opacityslider">
                <a class="close" @click="showslider = false">✕</a>
                <slick ref="slickpopup" :options="slickpopupOptions">
                    <div class="slide" :key="key" v-for="(n,key) in data">
                        <img :src="n.enlace" :alt="n.pie">
                    </div>
                </slick>
            </div>
        </div>

    </div>
</template>

<script>

    import Slick from 'vue-slick';

    import 'slick-carousel/slick/slick.css';

    export default {
        name: "slider-recurso",
        props: ['data'],
        data() {
            return {
                showslider: false,
                opacityslider: true,
                slickOptions: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                },
                slickpopupOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots: false,
                    arrows: true,
                    adaptiveHeight: false
                },
            }
        },
        components: { Slick },
        methods: {
            setslide(pos) {
                this.showslider = true;
                setTimeout(() => {
                    this.$refs.slickpopup.goTo(pos);
                    this.opacityslider = true;
                }, 200)
            }
        }
    }
</script>